<template>
    <div id="customer-address" v-if="customer">
        <div>
            <span><router-link to="/customer/dashboard">Return to Account Details</router-link></span>
        </div>
        <div class="mt-4">
            <h5>List of Addresses</h5>
        </div>
        <div class="mt-4" v-if="customer.shipping_addresses.length > 0" v-for="address in customer.shipping_addresses" :key="address.id">
            <div>
                <span class="address-user-name">{{ address.firstname }} {{ address.lastname }}</span>
                <span v-if="address.id == customer.default_shipping_address_id" class="font-italic ml-2">(Default)</span>
            </div>
            <p>
                {{ verifyAddressItem(address.company) }} <br v-if="verifyAddressItem(address.company)" />
                {{ verifyAddressItem(address.address1)? verifyAddressItem(address.address1) + ',' : null }} <br v-if="verifyAddressItem(address.address1)" />
                {{ verifyAddressItem(address.address2)? verifyAddressItem(address.address2) + ',' : null }} <br v-if="verifyAddressItem(address.address2)" />
                {{ verifyAddressItem(address.city) }}, {{ verifyAddressItem(address.zipcode) }} <br v-if="verifyAddressItem(address.city) && verifyAddressItem(address.zipcode)" />
                {{ verifyAddressItem(address.province) }} {{ verifyAddressItem(address.country) }} <br v-if="verifyAddressItem(address.province) && verifyAddressItem(address.country)" />
                {{ verifyAddressItem(address.phone) }}
            </p>
            <div class="address-action mb-4">
                <span v-if="address.id != customer.default_shipping_address_id" @click="setDefaultAddress(address)">Set default</span>
                <span v-if="address.id != customer.default_shipping_address_id"> | </span>
                <span @click="removeAddress(address)">Remove</span>
            </div>
        </div>
        <div v-else>
            <span class="font-italic">Please click button below to add new address.</span>
        </div>
        <div class="mt-3">
            <button class="btn" data-toggle="modal" data-target="#newAddressModal">Add A New Address</button>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="newAddressModal" tabindex="-1" role="dialog" aria-labelledby="newAddressModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="newAddressModalLabel">New Address</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.firstname" required placeholder="First Name">
                                </div>
                                <div class="col-md-6 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.lastname" required placeholder="Last Name">
                                </div>
                                <div class="col-md-12 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.company" required placeholder="Company">
                                </div>
                                <div class="col-md-12 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.address1" required placeholder="Address 1">
                                </div>
                                <div class="col-md-12 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.address2" required placeholder="Address 2">
                                </div>
                                <div class="col-md-6 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.city" required placeholder="City">
                                </div>
                                <div class="col-md-6 form-group">
                                    <select class="form-control" v-model="dataSubmit.province" required>
                                        <option :value="null" disabled>-Select Province-</option>
                                        <option v-for="item in provinces" :key="item.id" :value="item">{{ item }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.zipcode" required placeholder="Zip Code">
                                </div>
                                <div class="col-md-6 form-group">
                                    <input type="text" class="form-control" v-model="dataSubmit.phone" required placeholder="Phone">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" @click="submitNewAddress()">Add Address</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from './../../utils/API'
import { mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return {
            customer: null,
            dataSubmit: {
                firstname: null,
                lastname: null,
                company: null,
                address1: null,
                address2: null,
                city: null,
                province: null,
                zipcode: null,
                country: 'Malaysia', //by default,
                phone: null
            },
            provinces: [
                'Johor',
                'Kedah',
                'Kelantan',
                'Kuala Lumpur',
                'Labuan',
                'Melaka',
                'Negeri Sembilan',
                'Pahang',
                'Penang',
                'Perak',
                'Perlis',
                'Putrajaya',
                'Sabah',
                'Sarawak',
                'Selangor',
                'Terengganu'
            ]
        }
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        ...mapActions(['updateCustomerShippingAddress']),
        verifyAddressItem(_item){
            if(_item != null && _item != '-' && _item != '' && _item != ' ') return _item
            else return null
        },
        async submitNewAddress(){
            //update customer data
            this.customer.shipping_addresses.push(this.dataSubmit)
            let resCust = await API.put(`/customers/${this.customer.id}`, { shipping_addresses: this.customer.shipping_addresses })
            //renew customer data
            this.customer = null
            this.customer = resCust.data

            //dismiss modal
            $('#newAddressModal').modal('toggle');

            //reset form
            this.dataSubmit = {
                firstname: null,
                lastname: null,
                company: null,
                address1: null,
                address2: null,
                city: null,
                province: null,
                zipcode: null,
                country: 'Malaysia', //by default,
                phone: null
            }

            //call this function to automaticatlly verify & init default address
            await this.verifyDefaultAddress()
        },
        async verifyDefaultAddress(){
            if(this.customer.shipping_addresses.length == 0){
                this.customer.default_shipping_address_id = null
            }

            let isDefaultAddressExist = this.customer.shipping_addresses.find(_address => _address.id == this.customer.default_shipping_address_id)
            if(!isDefaultAddressExist && this.customer.shipping_addresses.length > 0){
                this.customer.default_shipping_address_id = this.customer.shipping_addresses[0].id
                let resCust = await API.put(`/customers/${this.customer.id}`, { default_shipping_address_id : this.customer.default_shipping_address_id })
                this.customer = resCust.data
            }
            //update Customer Cookies session
            this.updateCustomerShippingAddress({ shipping_addresses: this.customer.shipping_addresses, default_shipping_address_id: this.customer.default_shipping_address_id })
        },
        async setDefaultAddress(_address){
            //right now, set default is also setting the billing address
            let billingAddress = {
                firstname: _address.firstname,
                lastname: _address.lastname,
                company: _address.company,
                address1: _address.address1,
                address2: _address.address2,
                city: _address.city,
                province: _address.province,
                zipcode: _address.zipcode,
                country: 'Malaysia', //by default,
                phone: _address.phone
            }
            //update customer data
            let resCust = await API.put(`/customers/${this.customer.id}`, { default_shipping_address_id : _address.id, billing_address: billingAddress })
            //renew customer data
            this.customer = resCust.data
            //call this function to automaticatlly verify & init default address
            this.verifyDefaultAddress()
        },
        async removeAddress(_address){
            //update customer data
            if(_address.id == this.customer.default_shipping_address_id) this.customer.default_shipping_address_id = ''
            let ind = this.customer.shipping_addresses.findIndex(item => item.id == _address.id)
            this.customer.shipping_addresses.splice(ind, 1)
            let resCust = await API.put(`/customers/${this.customer.id}`, { shipping_addresses: this.customer.shipping_addresses, default_shipping_address_id : this.customer.default_shipping_address_id })
            //renew customer data
            this.customer = resCust.data
            //call this function to automaticatlly verify & init default address
            this.verifyDefaultAddress()
        }
    },
    async mounted(){
        let resCust = await API.get(`/customers/${this.currentCustomer.user.customer.id}`)
        this.customer = resCust.data
    }
}
</script>

<style scoped>
#customer-address {
    /* font-family: "Karla",arial,sans-serif; */
    font-size: 15px;
    line-height: 30px;
    color: #333;
}
#customer-address h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}
#customer-address a {
    color: #333;
}
#customer-address a:hover {
    color: #000;
    text-decoration: unset;
}

.btn {
    color: #fff;
    background-color: #747474;
    border-radius: unset;
    text-transform: uppercase;
    /* font-family: "Karla",sans-serif; */
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.15em;
    padding: 7px 20px;
}
.btn:hover {
    background-color: #000;
}

input[type=text].form-control,
select.form-control {
    border-radius: unset;
    border: 2px solid #e5e5e5;
    color: #999;
    padding: 6px 12px;
}

.address-user-name {
    /*font-family: "Roboto Slab",serif;*/
    font-size: 18px;
    font-weight: bold;
}
.address-action {
    margin-top: -15px;
    color: #666;
    cursor: pointer;
}
.address-action span:hover {
    color: #000;
}
</style>